
import { defineComponent, onMounted } from "vue";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
} from "@/core/helpers/breadcrumb";
import Entitytable from "@/components/EntityTable.vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default defineComponent({
  name: "kt-subscription-list",
  components: {
    Entitytable,
  },
  methods: {

  },
  data() {
    return {
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Role Manager");
    });

    const store = useStore();

    return { store };
  },
});
